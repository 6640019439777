
.crud {
    padding: var(--padding);
    overflow: hidden;
    height: 100%;
}

.crud .query-remove {
    justify-content: space-between;
    height: 30px;
}

.crud .query-remove,
.crud .operators {
    display: flex;
    gap: .5rem;
    margin-top: 4px;
}

.crud .operators.hidden {
    display: none;
}

.crud .display-flex {
    display: flex;
}

.crud button.bpdb-button.remove-button.hidden,
.crud button.bpdb-button.add-button.hidden {
    display: none;
}

.crud button.bpdb-button.remove-button {
    --button-color: #fff;
    --button-bg: #dc3545;
    --button-border-color: #dc3545;
    --button-hover-color: #fff;
    --button-hover-bg: #bb2d3b;
    --button-hover-border-color: #b02a37;
    --button-focus-shadow-rgb: 225,83,97;
    --button-active-color: #fff;
    --button-active-bg: #b02a37;
    --button-active-border-color: #a52834;
    --button-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --button-disabled-color: #fff;
    --button-disabled-bg: #dc3545;
    --button-disabled-border-color: #dc3545;
}

.crud button.bpdb-button.add-button {
    --button-color: #fff;
    --button-bg: #198754;
    --button-border-color: #198754;
    --button-hover-color: #fff;
    --button-hover-bg: #157347;
    --button-hover-border-color: #146c43;
    --button-focus-shadow-rgb: 60,153,110;
    --button-active-color: #fff;
    --button-active-bg: #146c43;
    --button-active-border-color: #13653f;
    --button-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --button-disabled-color: #fff;
    --button-disabled-bg: #198754;
    --button-disabled-border-color: #198754;
}

.crud .data-type-selection {
    display: flex;
    justify-content: space-between;
}

.crud .condition {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 4px;
    gap: 4px;
    font-size: 1rem;
}

.crud .condition > div {
    display: inline-flex;
    gap: 4px;
    flex-flow: row wrap;
}

.crud .condition .number-inputs {
    display: flex;
}

.crud .condition .logic {
    color: blue;
    background-color: wheat;
    border-radius: var(--border-radius);
    height: calc(1.8125rem + 2px);
    display: flex;
    line-height: 100%;
    text-align: center;
    cursor: pointer;
    width: 2rem;
    align-items: center;
    justify-content: center;
}

.crud .condition .selected {
    color: white;
    background-color: black;
    border-radius: var(--border-radius);
}

.crud .condition label {
    height: calc(1.8125rem + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
}

.crud .condition input {
    width: 4rem;
    height: calc(1.8125rem + 2px);
}


.date-input-calendar {
    position: relative;
    line-height: 1.5;
}

.date-input-calendar button {
    display: flex;
    align-items: center;
    --button-padding-y: 0.25rem;
    --button-padding-x: 0.5rem;
    --button-font-size: 0.875rem;
    --button-font-family: ;
    --button-font-weight: 400;
    --button-line-height: 1.5;
    --button-color: var(--body-color);
    --button-bg: #0000;
    --button-border-width: var(--border-width);
    --button-border-color: #0000;
    --button-border-radius: 3px;
    --button-hover-border-color: #0000;
    --button-box-shadow: inset 0 1px 0 #ffffff26,0 1px 1px rgba(0,0,0,.075);
    --button-disabled-opacity: 0.65;
    --button-focus-box-shadow: 0 0 0 0.25rem rgba(var(--button-focus-shadow-rgb),.5);
    background-color: var(--button-bg);
    border: var(--button-border-width) solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    color: var(--button-color);
    cursor: pointer;
    display: inline-block;
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: var(--button-line-height);
    padding: var(--button-padding-y) var(--button-padding-x);
    text-align: center;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}

.date-input-calendar .calendar-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    gap: 3px;
}

.date-input-calendar .calendar-input button {
    line-height: 1;
}

.date-input-calendar .calendar-input .calendar-icon {
    position: absolute;
    border: none;
    margin-left: 2px;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: #0000001a;
    border-radius: 3px;
}

.date-input-calendar .calendar-panel {
    width: 240px;
    position: absolute;
    z-index: 500;
    background-color: white;
    border-radius: 5px;
}

.date-input-calendar .calendar-panel > div:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.date-input-calendar .calendar-panel > div:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.date-input-calendar .calendar-panel .time-roller {
    display: flex;
    width: 100%;
}

.date-input-calendar .calendar-panel .roller-row {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    gap: 2px;
    text-align: center;
    background-color: bisque;
    width: 100%;
}

.date-input-calendar .calendar-panel .roller-row .plus5 {
    grid-row: 1;
    grid-column: 2 / 2;
}

.date-input-calendar .calendar-panel .roller-row .minus5 {
    grid-row: 3;
    grid-column: 2 / 2;
}

.date-input-calendar .calendar-panel .roller-row .selected {
    grid-row: 2;
    grid-column: 2 / 2;
}

.date-input-calendar .calendar-panel .roller-row .minus1 {
    grid-row: 2;
    grid-column: 1 / 1;
}

.date-input-calendar .calendar-panel .roller-row .plus1 {
    grid-row: 2;
    grid-column: 3 / 3;
}

.date-input-calendar .calendar-panel .week-row {
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 2px;
    text-align: center;
    background-color: burlywood;
}

.date-input-calendar .calendar-panel .selected {
    background-color: #1280c1;
    color: white;
    border-radius: 3px;
}

.date-input-calendar .calendar-panel .week-row > div,
.date-input-calendar .calendar-panel .roller-row > div {
    cursor: pointer;
    user-select: none;
}

.date-input-calendar .calendar-panel .calendar-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    background-color: gainsboro;
    margin-top: 2px;
}

.date-input-calendar .calendar-panel .calendar-header .month-selector {
    display: inline-block;
    font-size: .875rem;
    padding: .25rem .5rem;
}

.date-input-calendar .calendar-panel .confirm-cancel {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    background-color: wheat;
}

.date-input-calendar .calendar-panel .confirm-cancel button {
    display: block;
}

.date-input-calendar .calendar-panel .confirm-cancel .confirm {
    background-color: green;
    color: white;
}

.date-input-calendar .calendar-panel .calendar-header .year-selector {
    position: relative;
}

.date-input-calendar .calendar-panel .calendar-header .year-selector button {
    font-size: 1.1rem;
}

.date-input-calendar .calendar-panel .calendar-header .year-list {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.date-input-calendar .calendar-panel .calendar-header .year-list > div > div {
    height: 20px;
}

.date-input-calendar .calendar-panel .calendar-header .year-list > div {
    position: absolute;
    top: -6px;
    font-size: .875rem;
    overflow: auto;
    height: 180px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    user-select: none;
    width: 80%;
    text-align: center;
}
.dropdown-component {
    position: relative;
}

.dropdown-component .button-group {
    display: inline-flex;
    position: relative;
}

.dropdown-component .button-group > .dropdown-button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-component .button-group > .dropdown-button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.dropdown-component .button-group > .dropdown-button {
    flex: 1 1 auto;
    position: relative;
}

.dropdown-component .dropdown-button {
    --button-padding-x: 0.5rem;
    --button-padding-y: 0.25rem;
    --button-font-size: .875rem;
    --button-font-weight: 400;
    --button-line-height: 1.5;
    --button-color: #212529;
    --button-bg: transparent;
    --button-border-width: 1px;
    --button-border-color: transparent;
    --button-border-radius: .25rem;
    --button-hover-border-color: transparent;
    --button-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    --button-disabled-opacity: 0.65;
    --button-focus-box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, .5);
    display: inline-block;
    padding: var(--button-padding-y) var(--button-padding-x);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: var(--button-line-height);
    color: var(--button-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--button-border-width) solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    background-color: var(--button-bg);
    transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
}

.dropdown-component .dropdown-button:hover {
    color: var(--button-hover-color);
    background-color: var(--button-hover-bg);
    border-color: var(--button-hover-border-color);
}

.dropdown-component .arrow-up:empty::after,
.dropdown-component .arrow-down:empty::after {
    margin-left: 0;
}

.dropdown-component .arrow-down::after {
    border-bottom: 0;
    border-left: .3em solid #0000;
    border-right: .3em solid #0000;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
}

.dropdown-component .arrow-up::after {
    border-top: 0;
    border-left: .3em solid #0000;
    border-right: .3em solid #0000;
    border-bottom: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
}

.dropdown-component .title {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    max-width: 80vw;
}

.dropdown-component ul {
    max-height: 300px;
    max-width: 100px;
    overflow: auto;
    font-size: .8rem;
    position: absolute;
    transform: translate3d(0px, 32px, 0px);
    z-index: 1000;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .175);
    border-radius: .375rem;
    color: #212529;
    list-style: none;
    margin: 2px 0 0 0;
    min-width: 10rem;
    padding: .5rem 0;
    text-align: left;
    left: 0;
}

.dropdown-component ul > li > button {
    background-color: initial;
    border: 0;
    border-radius: 0;
    clear: both;
    color: #212529;
    display: block;
    font-weight: 400;
    padding: .25rem 1rem;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
}

.dropdown-component ul > li > button:hover {
    background-color: #f8f9fa;
    color: #212529;
}

.dropdown-component button.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.dropdown-component button.color-primary {
    --button-color: #fff;
    --button-bg: #0d6efd;
    --button-border-color: #0d6efd;
    --button-hover-color: #fff;
    --button-hover-bg: #0b5ed7;
    --button-hover-border-color: #0a58ca;
    --button-focus-shadow-rgb: 49,132,253;
    --button-active-color: #fff;
    --button-active-bg: #0a58ca;
    --button-active-border-color: #0a53be;
    --button-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --button-disabled-color: #fff;
    --button-disabled-bg: #0d6efd;
    --button-disabled-border-color: #0d6efd;
}
.dropdown-component button.color-info {
    --button-color: #000;
    --button-bg: #0dcaf0;
    --button-border-color: #0dcaf0;
    --button-hover-color: #000;
    --button-hover-bg: #31d2f2;
    --button-hover-border-color: #25cff2;
    --button-focus-shadow-rgb: 11,172,204;
    --button-active-color: #000;
    --button-active-bg: #3dd5f3;
    --button-active-border-color: #25cff2;
    --button-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --button-disabled-color: #000;
    --button-disabled-bg: #0dcaf0;
    --button-disabled-border-color: #0dcaf0;
}
.dropdown-component button.color-dark {
    --button-color: #fff;
    --button-bg: #212529;
    --button-border-color: #212529;
    --button-hover-color: #fff;
    --button-hover-bg: #424649;
    --button-hover-border-color: #373b3e;
    --button-focus-shadow-rgb: 66,70,73;
    --button-active-color: #fff;
    --button-active-bg: #4d5154;
    --button-active-border-color: #373b3e;
    --button-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --button-disabled-color: #fff;
    --button-disabled-bg: #212529;
    --button-disabled-border-color: #212529;
}
.dropdown-component button.color-success {
    --button-color: #fff;
    --button-bg: #198754;
    --button-border-color: #198754;
    --button-hover-color: #fff;
    --button-hover-bg: #157347;
    --button-hover-border-color: #146c43;
    --button-focus-shadow-rgb: 60,153,110;
    --button-active-color: #fff;
    --button-active-bg: #146c43;
    --button-active-border-color: #13653f;
    --button-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --button-disabled-color: #fff;
    --button-disabled-bg: #198754;
    --button-disabled-border-color: #198754;
}
.json-editor {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
}

.json-editor div:last-child {
    border-bottom-right-radius: 5px;
}

.json-editor .tree-stem {
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-left: 20px;
    padding-left: 5px;
}

.json-editor .tree-leaf {
    display: flex;
    background-color: wheat;
    margin-left: 20px;
    padding: 0 5px;
    border-top: 1px solid floralwhite;
    border-radius: 5px;
    align-items: center;
    gap: 5px;
}

.json-editor .tree-leaf:nth-child(2) {
    border-top: none;
}

.json-editor > div.tree-stem {
    margin-left: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-radius: 5px;
}

.json-editor .tree-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.json-editor .tree-name .tree-name-type {
    display: flex;
    align-items: center;
    min-width: 4rem;
    flex-grow: 1;
}

.json-editor .tree-name-type button {
    width: 2rem;
    border: none;
    height: 2rem;
    border-radius: 2px;
    color: blue;
}

.json-editor .tree-name .tree-name-type .node-type {
    color: #71c4dd;
    padding: 0 5px;
}

.json-editor input {
    border: 1px solid blue;
    background-color: transparent;
    margin: 3px 0;
    padding: 0 2px;
    min-width: 3rem;
    width: fit-content;
    flex-grow: 1;
}

.json-editor .tree-name .ops {
    margin: 3px 5px;
    flex-grow: 0;
    gap: 3px;
    display: flex;
}

.json-editor .tree-name .ops button {
    width: 2rem;
    border: 1px groove black;
    height: 2rem;
    border-radius: 2px;
}

.json-editor .edit-buttons {
    display: flex;
    justify-content: space-between;
}

.json-editor .edit-buttons button {
    width: 3rem;
    height: 2rem;
    border-radius: 5px;
    border: none;
}

.json-editor .edit-buttons button.editable {
    background-color: green;
    color: white;
}

.json-editor .edit-buttons button.save-button {
    background-color: #e80505;
    color: white;
}

.json-editor .show-text {
    background-color: green;
    color: white;
}

.json-editor .text-json {
    height: 300px;
    margin: 2px;
    border-radius: 3px;
    border: 1px solid green;
    padding: 0 2px;
}

.json-editor .text-json:focus {
    border-color: white;
}

.json-editor .text-json.error {
    outline: 2px solid red;
    border-color: transparent;
}

.login-page {
    background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 50px 0;
    background-color: #f0f0f0;
    border-radius: 5px;
    width: 500px;
    position: relative;
}

@media screen and (max-width: 500px) {
    .login-container {
        width: 100%;
    }
}

.login-container .phoneinput button {
    background-color: rgba(196, 11, 184, 0.8);
    color: white;
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-container .loginform button:hover:enabled {
    background-color: rgba(157, 13, 198, 0.8);
    color: white;
}

.login-container .loginform {
    width: 100%;
    --box-shadow: 0 0 1px rgba(168, 8, 8, 0.61) !important;
}

.login-container .loginform .logininput:focus-within {
    --box-shadow: 0 0 0 2px #e290fa inset;
}

.login-container .light-shadow {
    box-shadow: var(--box-shadow);
}

.login-container .loginlabel {
    text-align: start;
    font-weight: 400;
    color: #505a60e3;
    width: 90%;
    max-width: 380px;
    margin: 40px auto 20px auto;
    display: flex;
    gap: 20px;
}

.login-container .loginlabel > div {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: white;
}

.login-container .loginlabel > div.clicked {
    background-color: #249671;
    color: white;
}

.login-container .banner-container img {
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    background-color: #404f56;
}

.login-container .banner-container {
    display: flex;
    width: 100%;
    justify-content: center;
    line-height: 1;
}

.login-container .banner {
    font-size: 2.5rem;
    margin-left: 10px;
    color: #0c79bb;
    font-weight: 800;
    letter-spacing: .2rem;
    display: flex;
    align-items: center;
}

.login-container .codeinput {
    display: flex;
    flex-grow: 1;
}

.login-container .logininput {
    border-radius: 5px;
    height: 40px;
    font-size: 85%;
    background-color: white;
}

.login-container .invitation {
    cursor: pointer;
    text-decoration: underline;
    width: 120px;
    display: flex;
    position: absolute;
    right: 0;
    font-size: .8rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgb(228, 202, 202);
    white-space: nowrap;
}

.login-container .logininput > span {
  background-color: transparent;
  border: none;
  margin-left: 5px;
  font-size: 200%;
  color: #bb09d5bd;
}

.login-container .logininput input {
    height: 100%;
    display: block;
    outline: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    flex-grow: 1;
}

.login-container .phone-icon {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
}

.login-container .group-input {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.login-container .phoneinput {
    position: relative;
    width: 90%;
    max-width: 380px;
    margin: auto;
}

.login-container .getcodebutton:disabled {
    background-color: rgba(196, 11, 184, 0.3);
}

.login-container .getcodebutton {
    background-color: rgba(196, 11, 184, 0.8);
    width: 120px;
    font-size: 1rem;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 0 5px 5px 0;
}

.login-container .verifycodeinput {
    display: flex;
    position: relative;
    width: 90%;
    max-width: 380px;
    margin: auto;
    margin-top: 20px;
    gap: 5px;
}

.login-container .loginbutton {
    margin: auto;
    width: 90%;
    max-width: 380px;
}

.login-container .loginbutton button:disabled {
    opacity: .65;
}

.login-container .loginbutton button {
    background-color: rgba(196, 11, 184, 0.8);
    margin-top: 3rem;
    width: 100%;
    color: white;
    font-size: 18px;
    word-spacing: 40px;
    border: none;
}

.login-container .invitation-help {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b354cd;
}

.login-container .invitation-help .close-help {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 3px;
    background-color: #79797987;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container .invitation-help > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    height: min(90%, 600px);
    width: 90%;
    max-width: 460px;
    font-size: 1.2rem;
    text-align: center;
    color: #0f98c6;
}

.login-container .invitation-help .douyin {
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
}

.login-container .invitation-help .douyin span {
    margin-left: 10px;
}

.login-container .invitation-help img {
    margin-top: 10px;
    max-width: 400px;
    max-height: min(400px, 60%);
}
.dialog-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background: linear-gradient(#ffffffe3, antiquewhite);
}

dialog {
    min-width: fit-content;
    width: fit-content;
    height: fit-content;
    max-width: 100vw;
    min-height: 200px;
    max-height: 100vh;
    border: none;
    border-radius: 5px;
    box-shadow: 10px 10px 10px #cfcfcf;
    padding: 0;
    display: block;
    background-color: white;
}

dialog::backdrop {
    background: linear-gradient(#ffffffe3, antiquewhite);
}

dialog .caption {
    width: 100%;
    background-color: #3a9ef8;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    color: white;
}

dialog .caption .caption-text {
    flex-grow: 1;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

dialog .close {
    background-color: transparent;
    border: none;
    padding: 15px;
    font-size: 1.5rem;
    color: white;
}

@media screen and (max-width: 400px) {
    dialog {
        width: 100%;
    }
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 1000;
    padding: var(--padding);
}

.overlay .overlay-caption {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.overlay .overlay-caption > div {
    font-size: 1.2rem;
    font-weight: 800;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.overlay .overlay-caption button {
    border: none;
    background-color: #c874ee;
    color: white;
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
}
.spinner {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.spinner span {
    animation: spinning 2s linear infinite;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}

:root {
    --dataeditor-view-border: 1px solid darkgray;
}

/**
 * Hide arrows/spinners for number type input.
 * Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dataeditor-view {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.dataeditor-view .background-warning {
    background-color: #f8d7da;
}

.dataeditor-view .operations {
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.dataeditor-view .operations .background-green {
    background-color: #219221;
}

.dataeditor-view .operations .background-gray {
    background-color: #7d898d;
}

.dataeditor-view .operations .background-red {
    background-color: #d82323;
}

.dataeditor-view .operations button.edit-button {
    font-size: 1.5rem;
    width: 4rem;
    border: none;
}

.dataeditor-view .operations .remove-buttons {
    display: flex;
}

.dataeditor-view .operations .remove-buttons button:disabled {
    pointer-events: none;
    opacity: .65;
}

.dataeditor-view .operations .remove-buttons button.bpdb-button {
    border-radius: 0;
    width: fit-content;
}

.dataeditor-view .operations .remove-buttons button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.dataeditor-view .operations .remove-buttons button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dataeditor-view .operations .remove-buttons .background-secondary {
    background-color: gray;
}

.dataeditor-view .operations .close-button {
    font-size: 1.2rem;
    background-color: #acacac;
    width: 2rem;
}

.dataeditor-view .operations .close-button,
.dataeditor-view .operations button.edit-button {
    color: white;
    cursor: pointer;
    height: 2rem;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataeditor-view .remove-buttons button {
    width: fit-content;
    padding: 5px;
}

.dataeditor-view .dataeditor-info {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr;
}

.dataeditor-view .dataeditor-contents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
}

.dataeditor-view .dataeditor-row {
    display: grid;
    grid-template-columns: 30% 70%;
}

.dataeditor-view .dataeditor-row:nth-child(-n+2) .content,
.dataeditor-view .dataeditor-row:nth-child(-n+2) .title{
    border-top: var(--dataeditor-view-border);
}

.dataeditor-view .dataeditor-row .title.changed-info {
    color: white;
    background-color: #40c4ef;
}

.dataeditor-view .dataeditor-row .dataeditor-flex-row {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    height: 100%;
}

.dataeditor-view .dataeditor-row .dataeditor-flex-column {
    display: flex;
    flex-direction: column;
}

.dataeditor-view .dataeditor-row .content,
.dataeditor-view .dataeditor-row .title {
    display: flex;
    font-size: 1rem;
    padding: .25rem;
    min-height: 3rem;
    gap: 2px;
    flex-direction: column;
    justify-content: flex-start;
    border-left: var(--dataeditor-view-border);
    border-bottom: var(--dataeditor-view-border);
}

.dataeditor-view .dataeditor-row .content {
    border-right: var(--dataeditor-view-border);
    color: #003e80;
    word-wrap: anywhere;
    word-break: break-all;
    max-height: 300px;
    overflow: auto;
}

.dataeditor-view .dataeditor-row .content .center-content {
    display: flex;
    align-items: center;
    height: 100%;
}

.dataeditor-view .dataeditor-row .content button {
    font-size: 1rem;
}

.dataeditor-view .dataeditor-row .content button.add-remove-button {
    background-color: aliceblue;
    height: 2rem;
    width: 2rem;
}

.dataeditor-view .dataeditor-row .content button.add-new-row {
    width: 50%;
    margin: 2px auto;
    color: darkorchid;
    background-color: beige;
}

.dataeditor-view .dataeditor-row .content .checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    gap: 3px;
}

.dataeditor-view .dataeditor-row .content .checkboxes button {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .8rem;
}

.dataeditor-view .dataeditor-row .content .date-input input {
    text-align: center;
    height: 30px;
}

.dataeditor-view .dataeditor-row .content .date-input input,
.dataeditor-view .dataeditor-row .content .content-input {
    padding: 2px;
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
}

.dataeditor-view .dataeditor-row .content textarea {
    height: 160px;
}

.dataeditor-view .dataeditor-row .content textarea:disabled {
    resize: none;
}

.dataeditor-view .dataeditor-row .content .date-input input:disabled,
.dataeditor-view .dataeditor-row .content .content-input:disabled {
    border: 1px solid transparent;
    color: #003e80;
}

.dataeditor-view .dataeditor-row .content > div:nth-child(even) .date-input {
    background-color: linen;
    border-radius: 5px;
}

.dataeditor-view .dataeditor-row .content .date-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dataeditor-view .dataeditor-row .title {
    justify-content: center;
    align-items: end;
    text-align: end;
    word-break: keep-all;
}

.dataeditor-view .date-picker-container {
    position: absolute;
    top: 38px;
    left: 0;
}

@media screen and (max-width: 500px) {
    .dataeditor-view {
        flex-direction: column;
    }
    .dataeditor-view .dataeditor-info.specific {
        grid-gap: 0;
        grid-template-columns: repeat(1, 1fr);
    }
    .dataeditor-view div.dataeditor-contents:not(:nth-child(1)) {
        margin-top: -1px;
    }
    .dataeditor-view .dataeditor-contents {
        display: flex;
        flex-direction: column;
    }
    .dataeditor-view .dataeditor-row:nth-child(-n+2) .content,
    .dataeditor-view .dataeditor-row:nth-child(-n+2) .title{
        border-top: none;
    }
    .dataeditor-view .dataeditor-row:first-child .content,
    .dataeditor-view .dataeditor-row:first-child .title{
        border-top: var(--dataeditor-view-border);
    }
}

.bpdb {
    --border-radius: .25rem;
    --border-width: 1px;
    --body-color: #212529;
    --border-color: #53a5d7;
}

.bpdb input.bpdb-input::placeholder {
    color: #212529bf;
    opacity: 1;
}

.bpdb input.bpdb-input,
.bpdb textarea.bpdb-input {
    border-radius: .25rem;
    border-radius: var(--border-radius);
    font-size: .875rem;
    min-height: calc(1.5em + .5rem + 2px);
    min-height: calc(1.5em + .5rem + var(--border-width)*2);
    padding: .25rem .5rem;
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border: var(--border-width) solid var(--border-color);
    color: #212529;
    color: var(--body-color);
    display: block;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.bpdb input.bpdb-input:focus,
.bpdb textarea.bpdb-input:focus {
    background-color: #fff;
    background-color: var(--body-bg);
    border-color: #86b7fe;
    box-shadow: 0 0 0 .25rem #0d6efd40;
    color: #212529;
    color: var(--body-color);
    outline: 0;
}

.bpdb button.bpdb-button {
    --button-padding-y: 0.25rem;
    --button-padding-x: 0.5rem;
    --button-font-size: 0.875rem;
    --button-font-family: ;
    --button-font-weight: 400;
    --button-line-height: 1.5;
    --button-color: var(--body-color);
    --button-bg: #0000;
    --button-border-width: var(--border-width);
    --button-border-color: #0000;
    --button-border-radius: var(--border-radius);
    --button-hover-border-color: #0000;
    --button-box-shadow: inset 0 1px 0 #ffffff26,0 1px 1px rgba(0,0,0,.075);
    --button-disabled-opacity: 0.65;
    --button-focus-box-shadow: 0 0 0 0.25rem rgba(var(--button-focus-shadow-rgb),.5);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-bg);
    border: var(--button-border-width) solid var(--button-border-color);
    border-radius: var(--button-border-radius);
    color: var(--button-color);
    cursor: pointer;
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: var(--button-line-height);
    padding: var(--button-padding-y) var(--button-padding-x);
    text-align: center;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}

.bpdb button.bpdb-button.button-primary,
.bpdb button.button-primary {
    --button-color: white;
    --button-bg: #066dda;
}

.bpdb button.bpdb-button.button-secondary,
.bpdb button.button-secondary {
    --button-color: white;
    --button-bg: #7d898d;
}

.tip_on_top {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    z-index: 20000;
    flex-direction: column;
    color: white;
    align-items: center;
    pointer-events: none;
}

.tip_on_top > div {
    padding: 5px 10px;
    width: 70%;
    max-width: 300px;
    background-color: #15937b;
    pointer-events: auto;
    border-bottom: 1px solid #0aa690;
    word-break: break-all;
    word-wrap: anywhere;
}

.tip_on_top > div:last-child {
    border-radius: 0 0 3px 3px;
    border-bottom: none;
}

@font-face {
  font-family: 'bpdbcrud';
  src:
    url('fonts/bpdbcrud.ttf?3wb62y') format('truetype'),
    url('fonts/bpdbcrud.woff?3wb62y') format('woff'),
    url('fonts/bpdbcrud.svg?3wb62y#bpdbcrud') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bpdbcrud-"], [class*=" bpdbcrud-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bpdbcrud' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bpdbcrud-textsms:before {
  content: "\e924";
}
.bpdbcrud-qr_code:before {
  content: "\e925";
}
.bpdbcrud-add:before {
  content: "\e904";
}
.bpdbcrud-add_circle:before {
  content: "\e906";
}
.bpdbcrud-add_circle_outline:before {
  content: "\e907";
}
.bpdbcrud-content_copy:before {
  content: "\e926";
}
.bpdbcrud-create:before {
  content: "\e902";
}
.bpdbcrud-remove:before {
  content: "\e905";
}
.bpdbcrud-remove_circle:before {
  content: "\e908";
}
.bpdbcrud-remove_circle_outline:before {
  content: "\e909";
}
.bpdbcrud-save:before {
  content: "\e903";
}
.bpdbcrud-undo:before {
  content: "\e90c";
}
.bpdbcrud-sd_storage:before {
  content: "\e90f";
}
.bpdbcrud-format_align_justify:before {
  content: "\e917";
}
.bpdbcrud-text_fields:before {
  content: "\e923";
}
.bpdbcrud-file_download:before {
  content: "\e915";
}
.bpdbcrud-phone_android:before {
  content: "\e927";
}
.bpdbcrud-camera:before {
  content: "\e910";
}
.bpdbcrud-camera_alt:before {
  content: "\e911";
}
.bpdbcrud-picture_as_pdf:before {
  content: "\e921";
}
.bpdbcrud-local_printshop:before {
  content: "\e922";
}
.bpdbcrud-apps:before {
  content: "\e919";
}
.bpdbcrud-arrow_drop_down:before {
  content: "\e91d";
}
.bpdbcrud-arrow_drop_up:before {
  content: "\e91e";
}
.bpdbcrud-arrow_left:before {
  content: "\e91f";
}
.bpdbcrud-arrow_right:before {
  content: "\e920";
}
.bpdbcrud-arrow_back_ios:before {
  content: "\e91a";
}
.bpdbcrud-arrow_forward_ios:before {
  content: "\e91b";
}
.bpdbcrud-cached:before {
  content: "\e916";
}
.bpdbcrud-delete:before {
  content: "\e90a";
}
.bpdbcrud-done:before {
  content: "\e90d";
}
.bpdbcrud-search:before {
  content: "\e90e";
}
.bpdbcrud-date_range:before {
  content: "\e91c";
}
.bpdbcrud-imagesearch_roller:before {
  content: "\e918";
}
.bpdbcrud-open_in_full:before {
  content: "\e90b";
}
.bpdbcrud-scissors:before {
  content: "\e914";
}
.bpdbcrud-rotate-cw:before {
  content: "\e912";
}
.bpdbcrud-rotate-ccw:before {
  content: "\e913";
}
.bpdbcrud-clear:before {
  content: "\e901";
}
.bpdbcrud-sign-out:before {
  content: "\e900";
}
.bpdbcrud-spinner3:before {
  content: "\e97c";
}
.bpdbcrud-spinner8:before {
  content: "\e981";
}
.bpdbcrud-backward2:before {
  content: "\ea1f";
}
.bpdbcrud-forward3:before {
  content: "\ea20";
}
.bpdbcrud-previous2:before {
  content: "\ea23";
}
.bpdbcrud-next2:before {
  content: "\ea24";
}
.bpdbcrud-sort-alpha-asc:before {
  content: "\ea48";
}
.bpdbcrud-sort-alpha-desc:before {
  content: "\ea49";
}
.bpdbcrud-sort-numeric-asc:before {
  content: "\ea4a";
}
.bpdbcrud-sort-numberic-desc:before {
  content: "\ea4b";
}
.bpdbcrud-sort-amount-asc:before {
  content: "\ea4c";
}
.bpdbcrud-sort-amount-desc:before {
  content: "\ea4d";
}
.result-list {
    width: 100%;
    font-size: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.result-list .list-table {
    overflow: auto;
    width: 100%;
}

.result-list .only-show-excel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 30px;
}

.result-list .pagination-excel {
    display: flex;
    width: 100%;
    max-width: 500px;
    margin-top: 4px;
    gap: 4px;
    justify-content: space-between;
}

.result-list button.download-excel {
    background-color: #0d6efd;
    color: white;
    height: 100%;
}

.result-list .pagination-excel .pagination.invisible {
    visibility: hidden;
}

.result-list .pagination-excel .pagination {
    display: flex;
    list-style: none;
    flex-grow: 1;
    margin-bottom: 0;
    --pagination-border-radius: 5px;
}

.result-list .pagination-excel .pagination .page-info {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.result-list .pagination-excel .pagination li {
    display: flex;
    background-color: white;
    border: 1px solid #53a5d7;
    color: blue;
    font-size: 1rem;
    padding: 4px;
    position: relative;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.result-list .pagination-excel .pagination li:not(:first-child) {
    border-left: none;
}

.result-list .pagination-excel .pagination li button {
    padding: .25rem;
    --button-line-height: 1;
}

.result-list .pagination-excel .pagination li:last-child {
    border-bottom-right-radius: var(--pagination-border-radius);
    border-top-right-radius: var(--pagination-border-radius);
}

.result-list .pagination-excel .pagination li:first-child {
    border-bottom-left-radius: var(--pagination-border-radius);
    border-top-left-radius: var(--pagination-border-radius);
}

.result-list .pagination-excel .pagination li.disabled {
    background-color: #e7e7e7;
    pointer-events: none;
}

.result-list button {
    line-height: 1;
    padding: 8px 12px;
    min-width: 3rem;
}

.result-list button span {
    vertical-align: middle;
}

.result-list .table-table {
    display: table;
    border-collapse: collapse;
    overflow: hidden;
    width: 100%;
    font-size: .875rem;
}

.result-list .table-header {
    display: table-header-group;
    text-align: center;
    font-weight: 400;
    position: sticky;
    top: 0;
}

.result-list .table-header > div {
    display: table-cell;
    padding: .5rem;
    background-color: white;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    word-break: keep-all;
}

.result-list .table-line {
    display: table-row;
    border-width: 1px 0;
    border-style: solid;
    border-color: #dee2e6;
}

.result-list .table-line .column-title {
    display: none;
}

.result-list .table-line .column {
    display: table-cell;
    vertical-align: middle;
    border-width: 0 1px;
    border-style: solid;
    border-color: #dee2e6;
    padding: 5px;
    width: fit-content;
    max-width: max(200px, 30vw);
}

.result-list .table-line .column.operation {
    width: 4rem;
}

.result-list .table-line .operation button {
    word-break: keep-all;
    border: none;
    border-radius: 5px;
    background-color: #018e01;
    color: white;
    cursor: pointer;
}

.result-list .table-line .operation button.clicked {
    background-color: #c2531c;
}

.result-list .table-line .column-content {
    width: auto;
    word-wrap: break-word;
    max-height: 200px;
    overflow-y: auto;
}

@media screen and (max-width: 500px) {
    .result-list .table-line .column {
        display: table-row;
        border-width: 1px;
    }
    .result-list .table-line .operation {
        text-align: center;
        padding: 2px 0;
        background-color: #d4f1f2;
    }
    .result-list .table-line .column-title {
        display: table-cell;
        vertical-align: middle;
        text-align: end;
        white-space: nowrap;
        padding: .5rem;
        width: 8rem;
    }
    .result-list .table-line {
        margin-top: .5rem;
        display: table;
        width: 100%;
        word-wrap: anywhere;
    }
    .result-list .table-header {
        display: none;
    }
    .result-list .table-line .column-content {
        display: table-cell;
        border-width: 0 1px;
        border-style: solid;
        border-color: #dee2e6;
        vertical-align: middle;
        white-space: normal;
        word-wrap: anywhere;
    }
}


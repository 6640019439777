.image-Image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    position: relative;
}

.image-Image.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: gray;
    z-index: 400;
}

.image-Image.full-screen img {
    max-width: 100%;
    max-height: 100%;
}

.image-Image.full-screen .close-full-screen {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: orange;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.image-Image .full-screen-button {
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    color: white;
    background-color: #27be1a;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    font-size: .875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px 2px white;
}


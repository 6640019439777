.App-main {
    width: 100%;
    height: 100%;
}

.nav-header .tertiary-panel .personal-info {
    width: 200px;
    height: 300px;
    border-radius: 2px;
    position: relative;
}

.nav-header .tertiary-panel .personal-info .phone {
    color: #0f0f0f;
    border-bottom: 1px solid darkgray;
    font-size: 1rem;
}

.nav-header .tertiary-panel .personal-info .account {
    color: #0f0f0f;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.nav-header .tertiary-panel .personal-info .phone > span:first-child {
    background-color: #259fbb;
    padding: 0 10px;
    color: white;
    margin-right: 5px;
    border-radius: 2px;
}

.nav-header .tertiary-panel .personal-info .sign-out-button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007cff;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    padding: 5px;
}

.nav-header .tertiary-panel {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 43px;
    right: 10px;
    z-index: 300;
    background-color: #fbfbfb;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 0 10px 5px aliceblue;
    border: 2px solid #61ceda;
}

.nav-header .tertiary {
    flex-direction: column;
}

.nav-header .dehaze {
    width: 22px;
    margin: 2px;
    height: 3px;
    background-color: white;
    border-radius: 2px;
}

.lighting-button {
    letter-spacing: 2px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    user-select: none;
    background-color: transparent;
    border: 1px solid white;
    display: flex;
    align-items: center;
    color: inherit;
}

.lighting-button:hover {
    color: white;
    box-shadow: 0 0 2px 2px rgb(223, 213, 213);
}

.lighting-button.on {
    box-shadow: 0 0 2px 2px rgb(223, 213, 213);
    color: white;
    background-color: #086b08;
}

.nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.nav-buttons .switch span {
    font-size: 120%;
}

.clear-history {
    display: flex;
    align-items: center;
    margin: 5px;
}

.clear-history span {
    background-color: #ff4800;
    height: 100%;
    align-items: center;
    display: flex;
    width: 40px;
    justify-content: center;
    color: white;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
}

.search-history-back {
    position: absolute;
    z-index: 201;
    width: 100%;
    background-color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.search-history-back .close-search {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 40px;
    background-color: #f1eeee;
    border-radius: 5px;
}

.search-collapsed {
    position: absolute;
    right: 0px;
    z-index: 205;
    margin: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #64c7d8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 5px #25939e;
    color: white;
}

.search-history {
    margin: 5px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: white;
}

.search-history input {
    padding-right: 60px;
}

.search-history span {
    cursor: pointer;
    background-color: #0777ae;
    position: absolute;
    right: 0px;
    color: white;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 5px;
    border-radius: 0 .25rem .25rem 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0px;
}

.clipboard {
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    padding: 5px;
}

.message-selection:hover, .copy:hover, .hearing:hover {
    background-color: #585b5b70;
    color: white;
}

.message-selection {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    color: #373f4059;
}

.history {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;
    width: 100%;
    position: relative;
    /* 针对火狐浏览器 */
    scrollbar-width: none;
    -ms-overflow-style: none;  /* 针对IE10+和Edge */
}

/* 针对Webkit内核浏览器，如Chrome和Safari */
.history::-webkit-scrollbar {
    display: none;
}

.history .history-container:first-child {
    margin-top: 50px;
}

.history-assistant.history-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

.history-user:first-child {
    margin-top: 0;
}

.history-user {
    margin-top: 8px;
    background-color: #dcdce3;
    position: relative;
}

.history-assistant.error {
    color: red;
}

.history-assistant {
    background-color: #b7b5b536;
    position: relative;
}

.bottom-info-container {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    align-items: center;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

.loading-dots {
    display: inline-flex;
    gap: 5px; /* 控制点之间的间距 */
}

.loading-dots span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #000;
    border-radius: 50%;
    animation: blink 1.2s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.3s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.6s;
}

.history-content p {
    margin-bottom: 0;
}

.history-content pre {
    color: white;
    background-color: #1d2933;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    margin: auto auto 2rem auto;
    max-width: 1024px;
}

.history-content .code-header,
.history-content .mermaid-header {
    display: flex;
    background-color: #304d62;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: white;
    max-width: 1024px;
    margin: auto;
}

.history-content .code-header > div,
.history-content .mermaid-header > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.history-content .mermaid-header {
    background-color: #f8f4bf;
    color: black;
}

.history-content .code-copy-button,
.history-content .mermaid-copy-button,
.history-content .mermaid-text-button {
    border: none;
    background-color: transparent;
    color: white;
}

.history-content .mermaid-copy-button {
    color: brown;
}

.history-content .mermaid-text-button {
    color: cornflowerblue;
    display: flex;
}

@keyframes blink-cursor {
    0% {
        background-color: #2a2b2ac9;
    }
    50% {
        background-color: transparent;
    }
    100% {
        background-color: #2a2b2ac9;
    }
}

.blinking-cursor {
    background-color: #2a2b2ac9;
    display: inline;
    animation-name: blink-cursor; /* 应用定义的闪烁动画 */
    animation-duration: 1s; /* 动画周期时长 */
    animation-iteration-count: infinite; /* 动画循环次数：无限次 */
    animation-timing-function: step-end; /* 动画计时：结束时 */
    padding: 0 3px;
    margin-left: 4px;
}

.history-content .error-message {
    background-color: red;
    color: white;
    border-radius: 3px;
    padding: 5px;
    margin: 10px;
}

.top-panel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 300;
}

.synthesizing {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    margin: -1rem;
    background-color: #000000b0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
}

.synthesizing span {
    font-size: 2rem;
}

.show-playback {
    position: relative;
    width: fit-content;
    background-color: #9d5f1c;
    pointer-events: auto;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.show-playback audio {
    width: 100%;
    border-radius: 5px;
}

.panel-close {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: -10px -10px 5px 0;
    color: white;
    font-size: 1.5rem;
}

.time-control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .5rem;
}

.time-control span {
    font-size: 1.5rem;
    color: black;
}

.time-control button {
    background-color: white;
    border-radius: 5px;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
}

@keyframes time-control-touched {
    0% {
        background-color: white;
    }
    50% {
        background-color: transparent;
    }
    100% {
        background-color: white;
    }
}

/**
 * For mobiles, the style of buttons when they are touched is controlled by
 * :hover then :active.
 */
.time-control button:hover, .time-control button:active {
    animation: 2s 0s time-control-touched;
}

.speed-control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: .5rem;
}

.recorder-back {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #00b7ff;
    border-radius: 5px;
    padding: 10px;
}

.recorder-panel .remain {
    font-size: 1rem;
}

.recorder {
    width: 10vh;
    border: 1px solid #00b7ff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.recorder-back.blink-border {
    animation: blink-border 1s linear infinite;
}

@keyframes blink-border  {
    0%,100%   { border-color: red; }
    50%  { border-color: transparent; }
}

.timer {
    color: green;
    font-weight: 800;
    display: flex;
    justify-content: center;
}

.timer span {
    font-size: clamp(20px, 4vh, 40px);
    width: 4vh;
    text-align: center;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinning-circle span {
    animation: spinner linear 1.2s infinite;
    transform-origin: center;
    display: inline-block;
}

.hidden-input {
    display: block;
    position: absolute;
    height: 1px;
    width: 1px;
    padding: 0;
    margin: 0;
    border: none;
    color: transparent;
    background-color: transparent;
    top: 0;
    left: 0;
}

@keyframes pressed-down-up {
    0% {
        color: red;
        opacity: 1;
    }
    50% {
        color: red;
        opacity: 0.5;
    }
    100% {
        color: #373f4059;
    }
}

.hearing {
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    padding: 5px;
}

.hearing.has-audio {
    color: #904aa45e;
}

.hearing.active {
    color: #9f05ca;
    animation: blink 1.2s infinite;
}

.history-container {
    display: flex;
    width: 100%;
    padding: 20px 0;
}

.history-right {
    width: 30px;
    position: sticky;
    right: 0;
    bottom: 20px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-self: end;
    align-items: center;
    gap: .5rem;
    z-index: 200;
    background-color: #d7d3cd;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #5b6e7161;
}

.history-left {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 10px;
    width: 30px;
    height: fit-content;
    align-items: center;
}

.history-content .datetime {
    position: absolute;
    top: -18px;
    right: 3px;
    font-size: .75rem;
    color: cadetblue;
}

.history-content {
    display: block;
    width: calc(100vw - 60px);
    position: relative;
}

.history-content pre.mermaid-pre {
    background-color: white;
    text-align: center;
}

.history-content .mermaid-code {
    display: none;
    color: black;
    text-align: start;
}

.history-content .vision-frame {
    overflow: auto;
    border-radius: 5px;
}

.history-content .vision-images {
    display: inline-flex;
    overflow: auto;
    gap: 1px;
    align-items: center;
    background-color: gray;
    padding: 1px;
    border-radius: 5px;
}

.history-content .vision-images img {
    border-radius: 5px;
}

.talky-panel {
    z-index: 220;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #bea2f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.talky-panel .cancel-talking {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.talky-panel .cancel-talking > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ff8f3c;
    border-radius: 10px;
    width: 160px;
    height: 60px;
}

.talky-panel .control-tips {
    position: absolute;
    bottom: 2px;
    margin: 0 2px;
    padding: 10px;
    line-height: 2;
    border: 1px solid aliceblue;
    border-radius: 3px;
    background-color: whitesmoke;
    color: #c86c6c;
    max-width: 496px;
}

.talky-panel .control-tips > div {
    display: inline;
}

.talky-panel .control-tips .push-button {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-color: #cecaca;
    position: relative;
    border-radius: 5px;
}

.talky-panel .record-button {
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    pointer-events: auto;
    background-color: gray;
}

.talky-panel .control-tips .pushable.blinking-mic {
    background: hsl(345deg 100% 47%);
}

.talky-panel.talky-ready .control-tips .pushable.blinking-mic {
    background: hsl(345deg 100% 47%);
}

.talky-panel .control-tips .pushable {
    font-size: 1rem;
    border-radius: 5px;
}

.talky-panel.talky-ready .control-tips .pushable {
    background-color: #6a6d93;
}

.talky-panel .control-tips .mic-off {
    width: 2rem;
    height: 2rem;
    border: none;
    font-size: 1rem;
    color: gray;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
}

.talky-panel .talky-control {
    position: fixed;
    width: 100%;
    max-width: 500px;
    bottom: 20px;
    height: 110px;
    padding: 10px 20px 0 20px;
    display: flex;
    justify-content: center;
}

button {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.talky-panel .talky-control .side-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
    margin: 5px;
}

.talky-panel .talky-control .push-button {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 12px;
    background-color: #cecaca;
    position: relative;
}

.talky-panel .talky-control .disable-talky-control {
    height: 100%;
    width: 100%;
    background-color: #8a8585a8;
    position: absolute;
    top: 0;
    left: 0;
}

.talky-panel.talky-ready .pushable {
    background: hsl(345deg 100% 47%);
}

.talky-panel .pushable {
    font-size: 3rem;
    color: white;
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: #6a6d93;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateY(-6px);
    -webkit-user-select: none;
    user-select: none;
}

.talky-panel .icon-mic1.blinking-mic {
    animation: blink 1.2s infinite;
}

.talky-panel .pushable.blinking-mic {
    transform: translateY(-2px);
}

.talky-panel .flash-sending {
    position: absolute;
    top: 6px;
    left: 10px;
    z-index: 2;
    font-size: 1rem;
}

.talky-panel .flash-sending span {
    color: transparent;
}

.talky-panel .flash-sending span.sending {
    color: white;
}

.talky-panel.talky-ready .flash-sending span.sending {
    color: blue;
}

.talky-panel .power-level {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.talky-panel .talky-panel-title {
    position: absolute;
    top: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c14b27;
}

.talky-panel .talky-panel-title div.title {
    font-size: 1rem;
    left: 10px;
    position: absolute;
    background-color: #e1680f;
    padding: 5px 10px;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
}

.talky-panel .talky-panel-title > span {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
}

.talky-panel .talky-panel-title .new-topic-button {
    background-color: #137086;
    color: white;
    font-weight: 200;
}

.talky-panel .talky-messages.in-progress {
    color: #ffffff80;
}

.talky-panel .talky-messages {
    height: calc(100vh - 170px);
    overflow-y: auto;
    width: 100%;
    position: absolute;
    top: 40px;
    background-color: #d9d9d9;
    -webkit-user-select: none;
    user-select: none;
    padding: 0 5px;
    color: white;
}

.talky-panel .talky-messages .topics {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0;
}

.talky-panel .new-topic {
    display: flex;
    font-size: 0;
    justify-content: center;
    border: 1px dashed #12afbf;
    margin: 0 10% 20px 10%;
}

.talky-panel .talky-ask-reply {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.talky-panel .talky-line.ask {
    justify-content: flex-start;
}

.talky-panel .talky-line.reply {
    justify-content: flex-end;
}

.talky-panel .talky-line {
    display: flex;
    gap: 5px;
    cursor: pointer;
    align-items: flex-start;
}

.talky-panel .talky-line > .avatar {
    position: relative;
    width: 2.1rem;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.talky-panel .talky-line.ask > .avatar {
    background-color: green;
}

.talky-panel .talky-line.reply > .avatar {
    background-color: grey;
}

.talky-panel .talky-line > .avatar:before {
    content: "";
    float: left;
    padding-top: 100%;
}

.talky-panel .talky-line > .contents {
    border-radius: 5px;
    padding: 5px;
    max-width: 70%;
    min-width: 60px;
}

.talky-panel .talky-line.ask > .contents {
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: green;
}

.talky-panel .talky-line.reply > .contents {
    background-color: grey;
}

.talky-panel .time-left {
    position: absolute;
    top: calc(50% - 120px);
    font-size: 1rem;
    width: 80%;
    max-width: 400px;
    height: 4rem;
    color: white;
    background-color: #d94040;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    letter-spacing: 2px;
    z-index: 2;
}

.talky-panel .instruction-panel {
    position: absolute;
    top: 40px;
    height: calc(100vh - 40px);
    width: 100%;
    background-color: #b7b5b5ba;
    display: flex;
    align-items: center;
    z-index: 2;
    flex-direction: column;
}

.talky-panel .instruction-panel .instruction-input {
    width: 80%;
    max-width: 400px;
    position: absolute;
    top: 30px;
}

.talky-panel .instruction-panel .instruction-input .instruction-buttons {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nosleep {
    display: block;
    width: 32px;
    height: 18px;
}

.nosleep video {
    width: 100%;
    height: 100%;
}


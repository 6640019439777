@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?4uvi3f') format('truetype'),
    url('fonts/icomoon.woff?4uvi3f') format('woff'),
    url('fonts/icomoon.svg?4uvi3f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-scissors:before {
  content: "\e922";
}
.icon-sliders:before {
  content: "\e92c";
}
.icon-rotate-ccw:before {
  content: "\e921";
}
.icon-rotate-cw:before {
  content: "\e920";
}
.icon-hearing:before {
  content: "\e924";
}
.icon-mic:before {
  content: "\e928";
}
.icon-mic_off:before {
  content: "\e923";
}
.icon-forward_10:before {
  content: "\e900";
}
.icon-forward_30:before {
  content: "\e901";
}
.icon-forward_5:before {
  content: "\e902";
}
.icon-replay_10:before {
  content: "\e903";
}
.icon-replay_30:before {
  content: "\e904";
}
.icon-replay_5:before {
  content: "\e905";
}
.icon-textsms:before {
  content: "\e906";
}
.icon-clear:before {
  content: "\e907";
}
.icon-content_copy:before {
  content: "\e908";
}
.icon-create:before {
  content: "\e909";
}
.icon-remove_circle_outline:before {
  content: "\e90a";
}
.icon-ask:before {
  content: "\e914";
}
.icon-reply:before {
  content: "\e92a";
}
.icon-send:before {
  content: "\e90b";
}
.icon-graphic_eq:before {
  content: "\e90c";
}
.icon-sd_storage:before {
  content: "\e90d";
}
.icon-wifi_tethering:before {
  content: "\e90e";
}
.icon-format_quote:before {
  content: "\e910";
}
.icon-keyboard_arrow_down:before {
  content: "\e911";
}
.icon-keyboard_arrow_up:before {
  content: "\e912";
}
.icon-phone_android:before {
  content: "\e913";
}
.icon-camera_alt:before {
  content: "\e915";
}
.icon-color_lens:before {
  content: "\e92d";
}
.icon-remove_red_eye:before {
  content: "\e91a";
}
.icon-check:before {
  content: "\e916";
}
.icon-do_not_disturb_alt:before {
  content: "\e917";
}
.icon-check_box:before {
  content: "\e918";
}
.icon-check_box_outline_blank:before {
  content: "\e919";
}
.icon-bookmark:before {
  content: "\e91b";
}
.icon-code:before {
  content: "\e92b";
}
.icon-search:before {
  content: "\e91c";
}
.icon-subject:before {
  content: "\e92e";
}
.icon-visibility_off:before {
  content: "\e929";
}
.icon-record_voice_over:before {
  content: "\e91d";
}
.icon-qr_code:before {
  content: "\e91f";
}
.icon-sign-out:before {
  content: "\e927";
}
.icon-ellipsis-h:before {
  content: "\e926";
}
.icon-picture:before {
  content: "\e925";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-mic1:before {
  content: "\e91e";
}
.icon-spinner10:before {
  content: "\e983";
}
.icon-stop2:before {
  content: "\ea1e";
}

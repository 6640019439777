.admin-panel {
    margin-top: 6px;
    width: 100%;
    --bs-border-color: #53a5d7;
}

.admin-panel .workspace {
    background-color: white;
    position: relative;
    overflow: auto;
}

.admin-panel .nav {
    flex-wrap: nowrap;
    font-size: 1rem;
}

.admin-panel .nav .buttons {
    margin: 0 15px -1px 15px;
    width: calc(100% - 10px);
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}

.admin-panel .nav .buttons::-webkit-scrollbar {
    display: none;
}

.admin-panel .nav .active {
    border-bottom: none;
}

.admin-panel .nav .extra-button {
    min-width: 4rem;
    padding-right: 1.5rem;
}

.admin-panel .nav .extra-button span {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #c2e8ee;
    border-radius: 2px;
    cursor: crosshair;
}

.modellist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding);
    gap: 5px;
}

.modellist .new-refresh {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.namelist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding);
}

.namelist .status-options {
    display: flex;
    gap: 2px;
    justify-content: space-between;
}

.namelist .search {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    max-width: var(--root-max-width);
}

.namelist .search input {
    border-radius: 5px;
    border: 1px solid #08998b;
    flex-grow: 1;
    padding: .25rem .5rem;
    font-size: 1rem;
}

.namelist .search button {
    width: 6rem;
}

.user-view {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: var(--padding, 5px);
}

.user-view .dataeditor-row.instruction {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    border: var(--dataeditor-view-border);
}

.user-view .json-editor {
    padding: var(--padding, 5px);
}

.user-view .instruction .right,
.user-view .instruction .left {
    border-right: none;
}

.user-view .instruction .right {
    border-top: var(--dataeditor-view-border);
}

.instruction-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: var(--padding, 5px);
}

.instruction-list .refresh-add-buttons {
    display: flex;
    justify-content: space-between;
}

.instruction-list .refresh-add-buttons button {
    width: 4rem;
    height: 2rem;
    border: none;
    border-radius: 5px;
    background-color: #165791;
    color: white;
    font-size: 1.5rem;
}

.instruction-list .instruction-view {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.instruction-list .instruction-view .instruction-id {
    background-color: chartreuse;
    border-radius: 5px;
    padding: 5px;
}

.instruction-list .instruction-view .instruction-id button.copy-button {
    border: none;
    background-color: #0a57bb;
    color: white;
    width: 1.4rem;
    height: 1.8rem;
    border-radius: 3px;
    margin-left: 10px;
}

.instruction-list .instruction-view .instruction-name {
    background-color: wheat;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.instruction-list .instruction-view .instruction-name button {
    border: none;
    width: 3rem;
    height: 100%;
}

.instruction-list .instruction-view .instruction-name button.save-button {
    width: 3rem;
    height: 2rem;
    border-radius: 5px;
    background-color: #e80505;
    color: white;
}

.instruction-list .instruction-view .instruction-name > div {
    height: 2rem;
    position: relative;
}

.instruction-list .instruction-view .instruction-name > div button {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: transparent;
}

.instruction-list .instruction-view .instruction-name > div button.hidden {
    display: none;
}

.instruction-list .instruction-view .instruction-name > div input {
    height: 100%;
    border: 1px solid green;
    border-radius: 3px;
    padding: 0 2px;
}

.instruction-list .instruction-map {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(4, 1fr);
}

.instruction-list .instruction-map button {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    padding: 5px;
    border-radius: 3px;
    background-color: #65cbea;
}

.instruction-list .instruction-map button.selected {
    background-color: #ff7800;
}

@media screen and (min-width: 500px) {
    #root {
        border-radius: 5px;
    }
    :root {
        --padding-y: 4px;
        --padding-x: 10px;
    }
    .user-view .dataeditor-row.instruction {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .user-view .instruction .left {
        border-right: var(--dataeditor-view-border);
    }
    .user-view .instruction .right {
        border-top: none;
    }
    .instruction-list .instruction-map {
        grid-template-columns: repeat(8, 1fr);
    }
}



.input-container {
    position: fixed;
    inset: auto 0 20px 0;
    display: flex;
    width: calc(100% - var(--padding-x, 10px) * 2);
    max-height: 110px;
    height: auto;
    align-items: center;
    z-index: 201;
    margin: 0 var(--padding-x);
}

.input-container .hide-chats {
    display: none;
}

.input-container .show-chats {
    display: block;
}

.input-container .prompt-input-mask {
    position: fixed;
    left: 0;
    top: 40px;
    width: calc(100% - 60px);
    margin: auto 30px;
    height: calc(100% - 40px);
}

.input-container .show-chats,
.input-container .vision-container {
    margin: 0;
}

.input-container .send-button.ready:hover {
    background-color: #046a82;
}

.input-container .send-button.ready {
    background-color: #07b563;
}

.input-container .send-button {
    pointer-events: auto;
    background-color: transparent;
    margin-right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: .5rem;
    border: none;
}

.input-container .send-button span {
    color: #a0a1a2;
}

.input-container .send-button.ready span {
    color: #ffffff;
}

.input-container .send-button span.sending {
    animation: blink 1.2s infinite;
}

.input-container .record-send-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    align-items: center;
    height: 44px;
    z-index: 2;
}

.input-container .record-ocr {
    display: flex;
    margin-left: 10px;
    gap: .25rem;
}

.input-container .chats-input textarea::placeholder {
    font-size: 70%;
    line-height: 2.2;
}

.input-container .chats-input textarea {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 50vh;

    resize: none;
    font-size: 16px;
    padding: 10px 40px 10px var(--textarea-padding-left, 80px);
    margin: auto;
    width: calc(100vw - var(--padding-x) * 2);
    margin-left: calc(-30px + var(--padding-x));
    position: relative;
    z-index: 1;
}

.input-container .chats-input {
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px + var(--padding-x) * 2);
    margin: auto calc(30px - var(--padding-x));
    pointer-events: none;
}

.input-container .chats-input > * {
    pointer-events: auto;
}

.input-container .quoted-chats {
    background-color: white;
    padding: 0 10px;
    border-radius: .25rem .25rem 0 0;
}

.input-container .quoted-chats-list {
    overflow: auto;
    max-height: 30vh;
}

.input-container .quoted-chats-mask {
    position: relative;
    bottom: 0;
    height: 10px;
    background-color: white;
}

.input-container .quotes {
    border-bottom: 1px solid #8e899138;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.input-container .quotes > div {
    margin-left: 20px;
    overflow: scroll;
    /* 针对火狐浏览器 */
    scrollbar-width: none;
    -ms-overflow-style: none;  /* 针对IE10+和Edge */
}

/* 针对Webkit内核浏览器，如Chrome和Safari */
.input-container .quotes > div::-webkit-scrollbar {
    display: none;
}

.input-container .quoted-chats-title {
    cursor: pointer;
    font-size: .75rem;
    font-weight: 200;
    display: flex;
    justify-content: space-between;
}

.input-container .quoted-chats-deselect {
    cursor: pointer;
    color: red;
    padding: 0;
    margin-right: 5px;
    position: absolute;
    background-color: white;
}

.input-container .record-button {
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    pointer-events: auto;
}

.input-container button[class~="record-button"]:disabled {
    background-color: grey;
}

.input-container .record-button.stopped {
    background-color: #1a7305b5;
}

.input-container .record-button.stopped:hover {
    background-color: #1a7305cf;
}

.input-container .record-button.recording {
    background-color: #c61212ba;
}

.input-container .record-button.recording:hover {
    background-color: #c61212;
}

.input-container .record-button.waiting {
    background-color: grey;
}

.snapshot-area {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 220;
    flex-direction: column;
}

.snapshot-area video {
    max-width: 100%;
    max-height: 100%;
}

.ocr-area {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 300;
    flex-direction: column;
}

button.ocr-area-close {
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 1px;
    background-color: #f7a053;
    border: none;
    width: 3rem;
    height: 2rem;
    color: white;
    font-size: 1.5rem;
}

button.ocr-area-close:hover {
    background-color: #f28d0c;
}

.input-container .ocr-button {
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    pointer-events: auto;
    background-color: #007BFF;
}

.input-container .ocr-button:hover {
    background-color: #0056b3;
}

.input-container .ocr-button:disabled {
    background-color: grey;
}

.input-container .vision-container {
    display: flex;
    width: fit-content;
}

.input-container .vision-area {
    border-radius: 5px;
    border: 2px solid grey;
    display: flex;
    max-width: 100%;
    overflow: auto;
    position: relative;
    background-color: #a8a5a5;
}

.input-container .vision-area > div {
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: grey;
    position: relative;
    height: 200px;
    width: 200px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-container .vision-area > div:last-child {
    border-right: none;
}

.input-container .vision-area > div > div.edit-remove {
    position: absolute;
    z-index: 200;
    padding: 0px;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
}

.input-container .vision-area > div > img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}


.paint-area {
    padding: var(--padding);
    height: calc(100vh - 110px);
    position: relative;
}

.painting-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
    grid-template-rows: repeat(auto-fill, 124px);
    gap: 4px;
    text-align: left;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: scroll;
    overflow-x: hidden;
    /* 针对火狐浏览器 */
    scrollbar-width: none;
    -ms-overflow-style: none;  /* 针对IE10+和Edge */
}

.painting-list .prompts {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* 针对火狐浏览器 */
    scrollbar-width: none;
    -ms-overflow-style: none;  /* 针对IE10+和Edge */
    font-size: .75rem;
    color: white;
    background-color: #919192;
}

.painting-list .prompts .revised_prompt {
    background-color: green;
    padding: 4px;
}

.painting-list .prompts .prompt {
    background-color: #2c7fa7;
    padding: 4px;
}

.painting-list .one-paint .show-prompts-button {
    border: none;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: transparent;
    z-index: 2;
    color: #eb00ff;
}

.painting-list .one-paint {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e6e7;
    border-radius: 5px;
}

.painting-list .image-list {
    height: 120px;
    width: 120px;
}

.painting-list .one-paint.blinking {
    animation: blinking-paint 3s linear infinite;
}

.model-search {
    position: absolute;
    inset: auto 0 0 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: inherit;
}

.model-search .search button {
    border: 1px solid purple;
    border-radius: 3px;
    background-color: purple;
    color: white;
    width: 2rem;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.model-search .search input {
    height: 100%;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid purple;
    padding-left: .25rem;
}

.model-search .search input:focus-visible {
    outline: none;
}

.model-search .model-selector {
    border-radius: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
}

@keyframes blinking-paint {
     25% {
         background-color: gray;
         opacity: .75;
     }
     50% {
         opacity: 0.5;
     }
     75% {
         opacity: .75;
     }
}


.instruction-content {
    padding: var(--padding);
    overflow: auto;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.instruction-caption {
    background-color: #090ba2;
    color: white;
    font-weight: 400;
    font-size: .875rem;
    padding: 0 2px;
}

.instruction-content .instruction-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.instruction-content .instruction-list-item .instruction-selector {
    border: 1px solid black;
    background-color: white;
    border-radius: 5px;
}

.instruction-content .instruction-list-item .instruction-selector.active {
    color: white;
    background-color: black;
}

